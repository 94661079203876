import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-miscellaneous',
  template: '<router-outlet></router-outlet>',

})
export class MiscellaneousComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
